import { default as analytics52FjWMLNiRMeta } from "/vercel/path0/pages/app/analytics.vue?macro=true";
import { default as _91id_93gSxIi5kCThMeta } from "/vercel/path0/pages/app/module/[id].vue?macro=true";
import { default as indexFH21AypnIkMeta } from "/vercel/path0/pages/app/module/index.vue?macro=true";
import { default as modulenCJ1JAMUdvMeta } from "/vercel/path0/pages/app/module.vue?macro=true";
import { default as pricingGFgfBBHsdrMeta } from "/vercel/path0/pages/app/pricing.vue?macro=true";
import { default as profileqpWb6e2c9bMeta } from "/vercel/path0/pages/app/profile.vue?macro=true";
import { default as settingsj6bquwhCrTMeta } from "/vercel/path0/pages/app/settings.vue?macro=true";
import { default as storagejDrcZf5MSOMeta } from "/vercel/path0/pages/app/storage.vue?macro=true";
import { default as appcIKN7O1TY8Meta } from "/vercel/path0/pages/app.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as onboardingewPMQ0QeckMeta } from "/vercel/path0/pages/onboarding.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as _91id_93G8HX1mcEQUMeta } from "/vercel/path0/pages/widget/[id].vue?macro=true";
export default [
  {
    name: appcIKN7O1TY8Meta?.name ?? "app",
    path: appcIKN7O1TY8Meta?.path ?? "/app",
    meta: appcIKN7O1TY8Meta || {},
    alias: appcIKN7O1TY8Meta?.alias || [],
    redirect: appcIKN7O1TY8Meta?.redirect,
    component: () => import("/vercel/path0/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: analytics52FjWMLNiRMeta?.name ?? "app-analytics",
    path: analytics52FjWMLNiRMeta?.path ?? "analytics",
    meta: analytics52FjWMLNiRMeta || {},
    alias: analytics52FjWMLNiRMeta?.alias || [],
    redirect: analytics52FjWMLNiRMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/analytics.vue").then(m => m.default || m)
  },
  {
    name: modulenCJ1JAMUdvMeta?.name ?? undefined,
    path: modulenCJ1JAMUdvMeta?.path ?? "module",
    meta: modulenCJ1JAMUdvMeta || {},
    alias: modulenCJ1JAMUdvMeta?.alias || [],
    redirect: modulenCJ1JAMUdvMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/module.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93gSxIi5kCThMeta?.name ?? "app-module-id",
    path: _91id_93gSxIi5kCThMeta?.path ?? ":id()",
    meta: _91id_93gSxIi5kCThMeta || {},
    alias: _91id_93gSxIi5kCThMeta?.alias || [],
    redirect: _91id_93gSxIi5kCThMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/module/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFH21AypnIkMeta?.name ?? "app-module",
    path: indexFH21AypnIkMeta?.path ?? "",
    meta: indexFH21AypnIkMeta || {},
    alias: indexFH21AypnIkMeta?.alias || [],
    redirect: indexFH21AypnIkMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/module/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: pricingGFgfBBHsdrMeta?.name ?? "app-pricing",
    path: pricingGFgfBBHsdrMeta?.path ?? "pricing",
    meta: pricingGFgfBBHsdrMeta || {},
    alias: pricingGFgfBBHsdrMeta?.alias || [],
    redirect: pricingGFgfBBHsdrMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/pricing.vue").then(m => m.default || m)
  },
  {
    name: profileqpWb6e2c9bMeta?.name ?? "app-profile",
    path: profileqpWb6e2c9bMeta?.path ?? "profile",
    meta: profileqpWb6e2c9bMeta || {},
    alias: profileqpWb6e2c9bMeta?.alias || [],
    redirect: profileqpWb6e2c9bMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsj6bquwhCrTMeta?.name ?? "app-settings",
    path: settingsj6bquwhCrTMeta?.path ?? "settings",
    meta: settingsj6bquwhCrTMeta || {},
    alias: settingsj6bquwhCrTMeta?.alias || [],
    redirect: settingsj6bquwhCrTMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/settings.vue").then(m => m.default || m)
  },
  {
    name: storagejDrcZf5MSOMeta?.name ?? "app-storage",
    path: storagejDrcZf5MSOMeta?.path ?? "storage",
    meta: storagejDrcZf5MSOMeta || {},
    alias: storagejDrcZf5MSOMeta?.alias || [],
    redirect: storagejDrcZf5MSOMeta?.redirect,
    component: () => import("/vercel/path0/pages/app/storage.vue").then(m => m.default || m)
  }
]
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding",
    path: onboardingewPMQ0QeckMeta?.path ?? "/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G8HX1mcEQUMeta?.name ?? "widget-id",
    path: _91id_93G8HX1mcEQUMeta?.path ?? "/widget/:id()",
    meta: _91id_93G8HX1mcEQUMeta || {},
    alias: _91id_93G8HX1mcEQUMeta?.alias || [],
    redirect: _91id_93G8HX1mcEQUMeta?.redirect,
    component: () => import("/vercel/path0/pages/widget/[id].vue").then(m => m.default || m)
  }
]