import revive_payload_client_0tTi0Twfwx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_q4Pts0R8qr from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5EpDFF2cyx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_qeKfj5Gp0G from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.13.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_5sgcsw0UeS from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_FuqOAHmf7U from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_DjWPtP2vsz from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.13.0_typescript@5.4.3_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_BKlA5OJDBV from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jtjhTbNho2 from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.1_rollup@4.13.0_vue@3.4.21/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import auth_redirect_YfeOxnhH7o from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.13.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import i18n_Z6Lbp3AYkh from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.13.0_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_YnctBrStIZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.6_eslint@8.57.0_floating-vue@5.2.2_rollup@4.13.0_sass@1.72.0_t_gbhofqgl6xhlzm7s6k7t4ocaum/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_I7Ipw4jwYn from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@4.13.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import fontawesome_client_YktGug6KNZ from "/vercel/path0/plugins/fontawesome.client.ts";
import highlight_client_IaNTAxQqnA from "/vercel/path0/plugins/highlight.client.ts";
export default [
  revive_payload_client_0tTi0Twfwx,
  unhead_q4Pts0R8qr,
  router_5EpDFF2cyx,
  supabase_client_qeKfj5Gp0G,
  payload_client_5sgcsw0UeS,
  check_outdated_build_client_FuqOAHmf7U,
  plugin_vue3_DjWPtP2vsz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BKlA5OJDBV,
  plugin_jtjhTbNho2,
  auth_redirect_YfeOxnhH7o,
  i18n_Z6Lbp3AYkh,
  chunk_reload_client_YnctBrStIZ,
  plugin_I7Ipw4jwYn,
  analytics_client_mtqVw3YRXk,
  fontawesome_client_YktGug6KNZ,
  highlight_client_IaNTAxQqnA
]