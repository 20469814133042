<script setup lang="ts">
const { locale } = useI18n();
import { useGeneralStore } from "@/stores/generalStore";
import { StagePlaySpotlight } from "vue-stage-play";

useHead({
  title: "Vendeo",
  meta: [
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    {
      name: "description",
      content:
        "Intégrez facilement des vidéos interactives sur votre site, engagez vos clients, et boostez vos ventes avec Vendeo. Essayez gratuitement dès maintenant.",
    },
    { name: "keywords", content: "Vendeo, Vidéos interactives, Augmenter les ventes, Engagement client, Intégration facile, Solution Vidéo, E-commerce vidéo, Réponse aux questions, Service client, Témoignages clients, Essai gratuit" },
    { name: "lang", content: locale.value },
    {
      name: "og:title",
      content: "Vendeo - Vidéos Interactives pour Augmenter Vos Ventes.",
    },
    {
      name: "og:description",
      content:
        "Intégrez facilement des vidéos interactives sur votre site, engagez vos clients, et boostez vos ventes avec Vendeo. Essayez gratuitement dès maintenant.",
    },
    {
      name: "og:keywords",
      content:
        "Vendeo, Vidéos interactives, Augmenter les ventes, Engagement client, Intégration facile, Solution Vidéo, E-commerce vidéo, Réponse aux questions, Service client, Témoignages clients, Essai gratuit",
    },
    { name: "og:locale", content: locale.value },
    { name: "og:type", content: "website" },
    { name: "og:site_name", content: "Vendeo" },
  ],
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    },
  ],
});

const store = useGeneralStore();
const theme = computed(() => store.theme);
</script>

<template>
  <StagePlaySpotlight>
    <Html :data-theme="theme" :lang="locale">
      <Body>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </Body>
    </Html>
  </StagePlaySpotlight>
</template>
