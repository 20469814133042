import { defineStore } from "pinia";

export const useGeneralStore = defineStore("general", {
  state: () => {
    return {
      isAdmin: false,
      isUsingAnotherAccount: <string | null>null,
      theme: "light",
      lang: "en",
      plan: "starter",
      customLimit: <number | null>null,
      account_id: "",
      period_end_tmp: <number | null>null,
      discover: <boolean | null>null,
    };
  },
  actions: {
    toggleTheme() {
      this.theme = this.theme === "dark" ? "light" : "dark";
    },
    setLocale(lang: string) {
      this.lang = lang;
    },
    setCustomLimit(limit: null | number) {
      this.customLimit = limit;
    },
    setAccount(account_id: string) {
      this.account_id = account_id;
    },
    setPlan(plan: string) {
      this.plan = plan;
    },
    setExpirationDate(period_end_tmp: number | null) {
      this.period_end_tmp = period_end_tmp;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
